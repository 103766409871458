import moment from 'moment'
import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { CreateAccommodationItemViewModel } from './models'
import {
  CreateItemProps,
  createAccommodationPayloadFromViewModel,
} from './utils'
import AccommodationFields from '../Common/AccommodationFields'
import { formOptions } from '../Common/Fields/config'

import {
  addToTripSaveErrorEvent,
  addToTripSaveEvent,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { useCreateTripItem } from 'tripPlanner/hooks/api'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'

function CreateAccommodation(props: CreateItemProps) {
  const { tripId, defaultDate } = props

  const { closeModal, goBack } = useModalCallbacks()

  const methods = useForm<CreateAccommodationItemViewModel>({
    ...formOptions,
    defaultValues: {
      type: 'ACCOMMODATION',
      sourceType: 'NA',
      isBooked: false,
      startDate: defaultDate,
      endDate: defaultDate ?
        moment(defaultDate).add(1, 'day').clone()?.format(ISO_DATE_FORMAT) :
        undefined,
      place: {},
    },
  })
  const { handleSubmit } = methods

  const onInvalid = useCallback(() => {
    fireInteractionEvent(addToTripSaveErrorEvent('accommodation_custom'))
  }, [])

  const { mutate: createMutate, isLoading } = useCreateTripItem({
    onSuccess: () => {
      fireInteractionEvent(addToTripSaveEvent('accommodation_custom'))
      closeModal()
    },
    onError: onInvalid,
  })

  const createAccommodationItem = useCallback(
    (viewModel: CreateAccommodationItemViewModel) => {
      createMutate({
        tripId,
        tripItem: createAccommodationPayloadFromViewModel(viewModel),
      })
    },
    [createMutate, tripId],
  )

  return (
    <>
      <ModalHeader
        title="Add your accommodation"
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      />
      <FormProvider {...methods}>
        <ModalBody>
          <ModalContent>
            <AccommodationFields tripId={tripId} />
          </ModalContent>
        </ModalBody>

        <ModalFooter
          primaryActionProps={{
            onClick: handleSubmit(createAccommodationItem, onInvalid),
            children: 'Save',
            disabled: isLoading,
          }}
        />
      </FormProvider>

      {isLoading && <LoadingIndicator floating />}
    </>
  )
}

export default CreateAccommodation
