import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { CreateNoteItemViewModel } from './models'
import { CreateItemProps, createNotePayloadFromViewModel } from './utils'
import { formOptions } from '../Common/Fields/config'
import NoteFields from '../Common/NoteFields'

import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import { useCreateTripItem } from 'tripPlanner/hooks/api'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'

function CreateNote(props: CreateItemProps) {
  const { tripId, defaultDate } = props

  const { closeModal, goBack } = useModalCallbacks()

  const methods = useForm<CreateNoteItemViewModel>({
    ...formOptions,
    defaultValues: {
      type: 'NOTE',
      sourceType: 'NA',
      startDate: defaultDate,
      isSectionHeader: false,
    },
  })
  const { handleSubmit } = methods

  const { mutate: createMutate, isLoading } = useCreateTripItem({
    onSuccess: () => {
      closeModal()
    },
  })

  const createNoteItem = useCallback(
    (viewModel: CreateNoteItemViewModel) => {
      createMutate({
        tripId,
        tripItem: createNotePayloadFromViewModel(viewModel),
      })
    },
    [createMutate, tripId],
  )

  return (
    <>
      <ModalHeader
        title="Add note"
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      />
      <FormProvider {...methods}>
        <ModalBody>
          <ModalContent>
            <NoteFields tripId={tripId} />
          </ModalContent>
        </ModalBody>

        <ModalFooter
          primaryActionProps={{
            onClick: handleSubmit(createNoteItem),
            children: 'Save',
            disabled: isLoading,
          }}
        />
      </FormProvider>
      {isLoading && <LoadingIndicator floating />}
    </>
  )
}

export default CreateNote
