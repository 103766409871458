import React from 'react'

import CreateAccommodation from './CreateAccommodation'
import CreateCar from './CreateCar'
import CreateCruise from './CreateCruise'
import CreateCustomThingToDo from './CreateCustomThingToDo'
import CreateFlight from './CreateFlight'
import CreateNote from './CreateNote'
import CreateTour from './CreateTour'
import CreateTransport from './CreateTransport'

import { ParentItemType, ThingsToDoItemType } from 'tripPlanner/types/tripItem'
import { assertUnreachable } from 'tripPlanner/utils'

interface Props {
  tripId: string
  defaultDate?: string
  itemType:
    | Exclude<ParentItemType, 'LE_BOOKINGS' | 'THINGS_TO_DO'>
    | ThingsToDoItemType
}

function CreateTripItem({ itemType, defaultDate, tripId }: Props) {
  switch (itemType) {
    case 'ACCOMMODATION':
      return <CreateAccommodation tripId={tripId} defaultDate={defaultDate} />
    case 'ATTRACTION':
      return (
        <CreateCustomThingToDo
          initialType="ATTRACTION"
          tripId={tripId}
          defaultDate={defaultDate}
        />
      )
    case 'CAR':
      return <CreateCar tripId={tripId} defaultDate={defaultDate} />
    case 'RESTAURANT_BAR':
      return (
        <CreateCustomThingToDo
          initialType="RESTAURANT_BAR"
          tripId={tripId}
          defaultDate={defaultDate}
        />
      )
    case 'EVENT':
      return (
        <CreateCustomThingToDo
          initialType="EVENT"
          tripId={tripId}
          defaultDate={defaultDate}
        />
      )
    case 'FLIGHT':
      return <CreateFlight tripId={tripId} defaultDate={defaultDate} />
    case 'NOTE':
      return <CreateNote tripId={tripId} defaultDate={defaultDate} />
    case 'TOUR_AND_CRUISES':
    case 'TOURS':
      return <CreateTour tripId={tripId} defaultDate={defaultDate} />
    case 'CRUISES':
      return <CreateCruise tripId={tripId} defaultDate={defaultDate} />
    case 'TRANSPORT':
      return <CreateTransport tripId={tripId} defaultDate={defaultDate} />
    case 'THING_TO_DO':
      return (
        <CreateCustomThingToDo
          initialType="THING_TO_DO"
          tripId={tripId}
          defaultDate={defaultDate}
        />
      )
    default:
      return assertUnreachable(itemType)
  }
}

export default CreateTripItem
