import { rem } from 'polished'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

// import ThingToDoFields from '../Common/ThingToDoFields'

import {
  CreateAttractionItemViewModel,
  CreateEventItemViewModel,
  CreateRestaurantBarItemViewModel,
  CreateThingToDoItemViewModel,
} from './models'
import {
  createAttractionPayloadFromViewModel,
  createEventPayloadFromViewModel,
  createRestaurantBarPayloadFromViewModel,
  createThingToDoPayloadFromViewModel,
} from './utils'
import CustomThingToDoFields from '../Common/CustomThingToDoFields'
import { formOptions } from '../Common/Fields/config'

import {
  AddToTripSavableElement,
  addToTripSaveErrorEvent,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import LineRestaurantIcon from 'components/Luxkit/Icons/line/LineRestaurantIcon'
import LineSightseeingIcon from 'components/Luxkit/Icons/line/LineSightseeingIcon'
import LineTicketIcon from 'components/Luxkit/Icons/line/LineTicketIcon'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import Group from 'components/utils/Group'
import { useCreateTripItem } from 'tripPlanner/hooks/api'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'

type ViewModel =
  | CreateAttractionItemViewModel
  | CreateEventItemViewModel
  | CreateRestaurantBarItemViewModel
  | CreateThingToDoItemViewModel

type CustomThingToDoTypes =
  | 'ATTRACTION'
  | 'EVENT'
  | 'RESTAURANT_BAR'
  | 'THING_TO_DO'

interface Props {
  tripId: string
  defaultDate?: string
  initialType?: CustomThingToDoTypes
}

const ChipContainer = styled(Group)`
  margin-bottom: ${rem(16)};
  margin-top: ${rem(20)};
`

const SELECT_OPTIONS: Array<{
  value: any
  label: string
  icon: React.ReactElement
}> = [
  { value: 'ATTRACTION', label: 'Attraction', icon: <LineSightseeingIcon /> },
  { value: 'EVENT', label: 'Event', icon: <LineTicketIcon /> },
  {
    value: 'RESTAURANT_BAR',
    label: 'Restaurant',
    icon: <LineRestaurantIcon />,
  },
  { value: 'THING_TO_DO', label: 'Other', icon: <LineMapMarkerIcon /> },
]

const errorEventArgs: Record<CustomThingToDoTypes, AddToTripSavableElement> = {
  ATTRACTION: 'experience_custom_attractions',
  EVENT: 'experience_custom_attractions',
  RESTAURANT_BAR: 'experience_custom_restaurants_and_bars',
  THING_TO_DO: 'experience_custom_other',
}

function CreateCustomThingToDo(props: Props) {
  const { tripId, initialType = 'ATTRACTION', defaultDate } = props

  const { closeModal, goBack } = useModalCallbacks()

  const defaultValues = useMemo<Record<CustomThingToDoTypes, ViewModel>>(
    () => ({
      ATTRACTION: {
        type: 'ATTRACTION',
        sourceType: 'NA',
        isBooked: false,
        startDate: defaultDate,
        place: { name: '' } as CreateAttractionItemViewModel['place'],
      },
      EVENT: {
        type: 'EVENT',
        name: '', // TODO: remove with contract updates
        sourceType: 'NA',
        isBooked: false,
        startDate: defaultDate,
        endDate: defaultDate,
        place: { name: '' } as CreateEventItemViewModel['place'],
      },
      RESTAURANT_BAR: {
        type: 'RESTAURANT_BAR',
        sourceType: 'NA',
        isBooked: false,
        startDate: defaultDate,
        place: { name: '' } as CreateRestaurantBarItemViewModel['place'],
      },
      THING_TO_DO: {
        type: 'THING_TO_DO',
        name: '', // TODO: remove with contract updates
        sourceType: 'NA',
        isBooked: false,
        startDate: defaultDate,
        endDate: defaultDate,
        startPlace: { name: '' } as CreateThingToDoItemViewModel['startPlace'],
        endPlace: {} as CreateThingToDoItemViewModel['endPlace'],
        isEndDateSame: true,
        isEndPlaceSame: true,
      },
    }),
    [defaultDate],
  )

  const methods = useForm<ViewModel>({
    ...formOptions,
    defaultValues: defaultValues[initialType],
  })

  const { handleSubmit, watch, setValue, reset } = methods

  const type = watch('type')

  const onInvalid = useCallback(() => {
    fireInteractionEvent(addToTripSaveErrorEvent(errorEventArgs[type]))
  }, [type])

  useEffect(() => {
    const newValues: ViewModel = {
      ...defaultValues[type],
      name: watch('name'),
      startDate: watch('startDate'),
      isBooked: watch('isBooked'),
      price: watch('price'),
    }

    reset(newValues)
  }, [reset, defaultValues, type, watch, defaultDate])

  const { mutate: createMutate, isLoading } = useCreateTripItem({
    onSuccess: () => {
      // TODO: analytics
      closeModal()
    },
    onError: onInvalid,
  })

  const createThingToDoItem = useCallback(
    (viewModel: ViewModel) => {
      switch (viewModel.type) {
        case 'ATTRACTION': {
          createMutate({
            tripId,
            tripItem: createAttractionPayloadFromViewModel(viewModel),
          })
          break
        }

        case 'EVENT': {
          createMutate({
            tripId,
            tripItem: createEventPayloadFromViewModel(viewModel),
          })
          break
        }

        case 'RESTAURANT_BAR': {
          createMutate({
            tripId,
            tripItem: createRestaurantBarPayloadFromViewModel(viewModel),
          })
          break
        }

        case 'THING_TO_DO': {
          createMutate({
            tripId,
            tripItem: createThingToDoPayloadFromViewModel(viewModel),
          })
          break
        }
      }
    },
    [createMutate, tripId],
  )

  return (
    <>
      <ModalHeader
        title="Add your own activity"
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      />
      <FormProvider {...methods}>
        <ModalBody>
          <ModalContent>
            <ChipContainer
              direction="horizontal"
              verticalAlign="center"
              gap={8}
              wrap="wrap"
            >
              {SELECT_OPTIONS.map((option) => (
                <FilterChip
                  key={option.value}
                  startIcon={option.icon}
                  onClick={() => setValue('type', option.value)}
                  selected={type === option.value}
                  size="medium"
                >
                  {option.label}
                </FilterChip>
              ))}
            </ChipContainer>
            <CustomThingToDoFields tripId={tripId} />
          </ModalContent>
        </ModalBody>

        <ModalFooter
          primaryActionProps={{
            onClick: handleSubmit(createThingToDoItem, onInvalid),
            children: 'Save',
            disabled: isLoading,
          }}
        />
      </FormProvider>
    </>
  )
}

export default CreateCustomThingToDo
